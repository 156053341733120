@use "sass:math";
// Links
// Style anchor elements.
$link-color: $primary;
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: underline;

// Nutzerdefinierte Fonts

// Font, line-height, and color for body text, headings, and more.
// stylelint-disable value-keyword-case
$font-family-sans-serif:      'Fago Pro', 'Droid Sans', 'Calibri', 'GillSans', sans-serif;
$font-family-serif:           serif;
$font-family-monospace:       monospace;
$font-family-base:            $font-family-sans-serif;
// stylelint-enable value-keyword-case

$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$font-size-lg:                ($font-size-base * 1.25);
$font-size-sm:                ($font-size-base * .875);

$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bold:            700;

$font-weight-base:            $font-weight-normal;
$line-height-base:            1.5;

// Standard headings
$h1-font-size:                $font-size-base * 1.75;
$h2-font-size:                $font-size-base * 1.5;
$h3-font-size:                $font-size-base * 1.25;
$h4-font-size:                $font-size-base * 1.125;
$h5-font-size:                $font-size-base;
$h6-font-size:                $font-size-base;

$headings-margin-bottom:      calc($spacer / 2);
$headings-font-family:        'Fago Pro', 'Roboto', 'Segoe UI', 'Arial', sans-serif;
$headings-font-weight:        bold;
$headings-line-height:        1.25;
$headings-color:              $primary;

// Display headings
$display1-size:               6rem;
$display2-size:               5.5rem;
$display3-size:               4.5rem;
$display4-size:               3.5rem;

$display1-weight:             300;
$display2-weight:             300;
$display3-weight:             300;
$display4-weight:             300;
$display-line-height:         $headings-line-height;

$lead-font-size:              ($font-size-base * 1.25);
$lead-font-weight:            300;

$small-font-size:             80%;

$text-muted:                  $gray-600;

$blockquote-small-color:      $gray-600;
$blockquote-font-size:        ($font-size-base * 1.25);

$hr-border-color:             rgba($black, .1);
$hr-border-width:             $border-width;

$mark-padding:                .2em;

$dt-font-weight:              $font-weight-bold;

$kbd-box-shadow:              inset 0 -.1rem 0 rgba($black, .25);
$nested-kbd-font-weight:      $font-weight-bold;

$list-inline-padding:         .5rem;

$mark-bg:                     #fcf8e3;

$hr-margin-y:                 $spacer;

label {
  color: $primary;
  font-family: $headings-font-family;
}

//Accordion
$accordion-color:                   $primary;
$accordion-button-bg:               rgba($black, .03);
$accordion-button-active-bg:        rgba($black, .03);
$accordion-button-active-color:     $accordion-color;
$accordion-button-color:            $accordion-color;

$accordion-icon-color:              $accordion-color;
$accordion-icon-active-color:       $accordion-icon-color;
$accordion-button-icon:             url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
$accordion-button-active-icon:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");

  // Navbar
$navbar-dark-toggler-border-color:  rgba($white, 1);
$navbar-dark-color:                 rgba($white, .8);
$navbar-dark-hover-color:           rgba($white, 1);

// Buttons
$btn-padding-y: 6px;
$btn-padding-x: 20px;
//$min-contrast-ratio:   3;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark:             $gray-900;
$yiq-text-light:            #ffffff;

$input-btn-focus-width:       .2rem;
$btn-focus-width:             $input-btn-focus-width;

$enable-shadows:            false;
$enable-gradients:          false;
$btn-active-box-shadow:     inset 0 3px 5px rgba(#000000, .125);
$btn-box-shadow:            inset 0 1px 0 rgba(#ffffff, .15), 0 1px 1px rgba(#000000, .075);

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  150;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
);
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px
);
// Borders
$border-width:                1px;
$border-color:                #ccc;


// Form validation

$form-feedback-icon-valid-color:    $form-feedback-valid-color;
$form-feedback-icon-invalid-color:  $form-feedback-invalid-color;
$form-feedback-icon-valid:          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' data-prefix='fas' data-icon='check-circle' class='svg-inline--fa fa-check-circle fa-w-16' viewBox='0 0 512 512'><path fill='currentColor' d='M504 256a248 248 0 1 1-496 0 248 248 0 0 1 496 0zM227.3 387.3l184-184a16 16 0 0 0 0-22.6L388.7 158a16 16 0 0 0-22.6 0L216 308l-70-70a16 16 0 0 0-22.7 0l-22.6 22.6a16 16 0 0 0 0 22.6l104 104a16 16 0 0 0 22.6 0z' style='fill:#28a74f;fill-opacity:.94117647'/></svg>");
$form-feedback-icon-invalid:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' data-prefix='fas' data-icon='exclamation-circle' class='svg-inline--fa fa-exclamation-circle fa-w-16' viewBox='0 0 512 512'><path fill='currentColor' d='M504 256a248 248 0 1 1-496 0 248 248 0 0 1 496 0zm-248 50a46 46 0 1 0 0 92 46 46 0 0 0 0-92zm-43.7-165.3 7.4 136a12 12 0 0 0 12 11.3h48.6a12 12 0 0 0 12-11.3l7.4-136a12 12 0 0 0-12-12.7h-63.4a12 12 0 0 0-12 12.7z' style='fill:#dc3545;fill-opacity:1'/></svg>");


$form-validation-states: ();
$form-validation-states: map-merge(
    (
        "valid": (
                "color": $success,
                "icon": $form-feedback-icon-valid
        ),
        "invalid": (
                "color": $danger,
                "icon": $form-feedback-icon-invalid
        ),
    ),
    $form-validation-states
);
