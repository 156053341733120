/* Nutzerdefiniertes SCSS und Standard-Bootstrap-SCSS-Module importieren */
@font-face {
  font-family: 'Fago Pro';
  src: url(../fonts/fagopro.otf);
}

/* Bootstrap Funktionen (_functions.scss) und Variablen (_variables.scss) importieren */
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

/* Themespezifische Anpassungen importieren */
@import 'custom';

/* Themespezifische Anpassungen importieren */
@import '~bootstrap/scss/bootstrap';

/* Mixins importieren */
@import 'mixins/responsive-borders';
@import 'mixins/buttons';